<template>
    <div class="layout-analytics-dashboard">
        <div class="grid">
            <div class="col-12">
                <div class="widget-header flex flex-wrap align-items-center justify-content-between pt-3 pb-5">
                    <div class="header-left-section flex align-items-center justify-content-start mb-3 md:mb-0">
                        <div class="profile relative">
                            <img src="demo/images/avatar/profile.jpg" width="64" alt="profile"
                                class="border-circle border-3 border-solid"/>
                            <span class="profile-badge absolute"><span class="dot block"></span></span>
                        </div>
                        <div class="ml-3 mb-2 md:mb-0">
                        <span class="text-4xl line-height-3 font-light block">Destaque, <span
                            class="font-normal">Maria da Silva</span> </span>
                            <span class="block subtext">Dezembro, 2022, R$ 1.800.000,00</span>
                        </div>
                    </div>
                    <div class="header-right-section flex align-items-center">
                        <Dropdown :options="analytics" v-model="selectedDrop" optionLabel="label" placeholder="Vendas x Analítico"
                                    :showClear="true" class="mb-2 md:mb-0"></Dropdown>

                        <Calendar v-model="calendar" :showIcon="true" class="ml-2 md:ml-4 mb-2 md:mb-0"></Calendar>
                    </div>
                </div>
            </div>

            <div class="col-12 lg:col-6">
                <div class="card widget-tabbedchart">
                    <div class="card-header font-bold">Produtos em Destaque</div>
                    <div class="tab-content flex flex-column md:flex-row">
                        <ul class="w-full list-none p-0 mb-5">
                            <li v-for="(item, i) of listItems" :key="i" @click="activeListItemIndex = i; activeListItem = item"
                                class="py-3 pr-2 pl-4 h-7rem flex cursor-pointer" :class="{'active-tab-listitem': activeListItemIndex === i}">
                                <img :src="item.image" width="64" class="mr-3">
                                <div class="flex align-items-center justify-content-between w-full">
                                    <div>
                                        <span class="font-bold block">{{item.text}}</span>
                                        <span class="subtext block mb-1">{{item.subtext}}</span>
                                        <span class="tab-badge font-bold px-1">{{item.ratio}}</span>
                                    </div>
                                    <i class="pi pi-chevron-right subtext"></i>
                                </div>
                            </li>
                        </ul>
                        <div class="w-full flex flex-column align-items-center justify-content-center">
                            <div class="bars flex align-items-end mb-4">
                                <div class="w-1rem h-2rem mr-2" :class="{'h-3rem active-bar': activeListItemIndex === 2 && activeTab === 0}"></div>
                                <div class="w-1rem h-3rem mr-2" :class="{'h-5rem active-bar': activeListItemIndex === 1 && activeTab !== 2}"></div>
                                <div class="w-1rem h-4rem mr-2"></div>
                                <div class="w-1rem h-6rem mr-2" :class="{'h-4rem active-bar': activeListItemIndex === 2 && activeTab !== 0}"></div>
                                <div class="w-1rem h-2rem mr-2" :class="{'h-4rem active-bar': activeListItemIndex === 1 && activeTab === 2}"></div>
                                <div class="w-1rem h-5rem mr-2" :class="{'h-4rem active-bar': activeListItemIndex === 0 && activeTab === 1}"></div>
                                <div class="w-1rem h-3rem mr-2" :class="{'h-4rem active-bar': activeListItemIndex === 0 && activeTab !== 1}"></div>
                                <div class="w-1rem h-1rem mr-2"></div>
                            </div>
                            <span class="font-bold">{{activeListItem.text}}</span>
                            <span class="subtext">{{activeListItem.subtext}}</span>
                        </div>
                    </div>
                    <div class="tabs flex align-items-center md:mt-0 mt-2">
                        <div class="flex flex-column align-items-center w-full py-2 cursor-pointer" :class="{'active-tab': activeTab === 0}" @click="onTabClick($event, 0)">
                            <i class="pi pi-sort-amount-down text-2xl mb-2"></i>
                            <span class="font-bold">Grandes Negócios</span>
                        </div>
                        <div class="flex flex-column align-items-center w-full py-2 cursor-pointer" :class="{'active-tab': activeTab === 1}" @click="onTabClick($event, 1)">
                            <i class="pi pi-chart-line text-2xl mb-2"></i>
                            <span class="font-bold">Médio</span>
                        </div>
                        <div class="flex flex-column align-items-center w-full py-2 cursor-pointer" :class="{'active-tab': activeTab === 2}" @click="onTabClick($event, 2)">
                            <i class="pi pi-star text-2xl mb-2"></i>
                            <span class="font-bold">Pequeno</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 lg:col-6">
                <div class="card">
                    <div class="card-header mb-4">
                        <span class="font-bold">Últimos Pedidos</span>
                        <Dropdown :options="orderWeek" v-model="selectedOrderWeek" optionLabel="name" @change="recentSales($event)"
                                    class="dashbard-demo-dropdown" :style="{'min-width': '8rem'}"></Dropdown>
                    </div>

                    <DataTable :value="products" :rows="5" responsiveLayout="scroll">
                        <Column header="Image">
                            <template #body="slotProps">
                                <img :src="'demo/images/product/' + slotProps.data.image" class="shadow-4" :alt="slotProps.data.image" width="50" />
                            </template>
                        </Column>
                        <Column field="name" header="Descrição" :sortable="true">
                            <template #body="slotProps">
                                {{slotProps.data.name}}
                            </template>
                        </Column>
                        <Column field="category" header="Categoria" :sortable="true">
                            <template #body="slotProps">
                                {{slotProps.data.category}}
                            </template>
                        </Column>
                        <Column field="price" header="Valor" :sortable="true">
                            <template #body="slotProps">
                                {{formatCurrency(slotProps.data.price)}}
                            </template>
                        </Column>
                        <Column header="Detalhes" headerClass="flex justify-content-center" bodyStyle="text-align: center">
                            <template #body>
                                <Button type="button" icon="pi pi-search" class="p-button-text p-button-plain" style="margin-right: .5rem"></Button>
<!--                                <Button type="button" icon="pi pi-times" class="p-button-text p-button-plain"></Button>-->
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>

            <div class="col-12">
                <div class="card widget-boxes p-0 grid grid-nogutter">
                    <div class="col-12 md:col-6 lg:col-3 py-3 px-6 border-none md:border-right-1 border-solid">
                        <div class="mb-3">
                            <Avatar icon="pi pi-shopping-cart" size="large" shape="circle" class="text-base font-bold"
                                    :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}"></Avatar>
                            <span class="text-xl ml-2">Pedidos</span>
                        </div>
                        <span class="block font-bold text-6xl mb-3">1420</span>
                        <ProgressBar :value="50" :showValue="false" class="mb-3"></ProgressBar>
                    </div>
                    <div class="col-12 md:col-6 lg:col-3 py-3 px-6 border-none md:border-right-1 border-solid">
                        <div class="mb-3">
                            <Avatar icon="pi pi-money-bill" size="large" shape="circle" class="text-base font-bold"
                                    :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}"></Avatar>
                            <span class="text-xl ml-2">Conversão</span>
                        </div>
                        <span class="block font-bold text-6xl mb-3">2.7k</span>
                        <ProgressBar :value="60" :showValue="false" class="mb-3"></ProgressBar>
                    </div>
                    <div class="col-12 md:col-6 lg:col-3 py-3 px-6 border-none md:border-right-1 border-solid">
                        <div class="mb-3">
                            <Avatar icon="pi pi-users" size="large" shape="circle" class="text-base font-bold"
                                    :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}"></Avatar>
                            <span class="text-xl ml-2">Clientes</span>
                        </div>
                        <span class="block font-bold text-6xl mb-3">25402</span>
                        <ProgressBar :value="80" :showValue="false" class="mb-3"></ProgressBar>
                    </div>
                    <div class="col-12 md:col-6 lg:col-3 py-3 px-6">
                        <div class="mb-3">
                            <Avatar icon="pi pi-comments" size="large" shape="circle" class="text-base font-bold"
                                    :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}"></Avatar>
                            <span class="text-xl ml-2">Leads</span>
                        </div>
                        <span class="block font-bold text-6xl mb-3">85</span>
                        <ProgressBar :value="30" :showValue="false" class="mb-3"></ProgressBar>
                    </div>
                </div>
            </div>

            <div class="col-12 md:col-6 xl:col-6">
                <div class="card height-100">
                    <div class="card-header widget-chat font-bold">Atividade Recente</div>
                    <div class="widget-chat">
                        <ul id="chatcontainer" class="list-none p-0 px-3 mt-4 mb-6 h-21rem overflow-y-auto">
                            <li v-for="chartMessage of chatMessages" :key="chartMessage" :class="['flex align-items-start mb-3', {'justify-content-start': chartMessage.nth, 'justify-content-end': !chartMessage.nth}]">
                                <img v-if="chartMessage.nth" :src="chartMessage.url" width="36" height="36" class="border-circle">
                                <div class="ml-2" v-if="chartMessage.nth">
                                    <span class="font-bold mr-3">{{chartMessage.from}}</span><span class="subtext">20 minutos</span>
                                    <div class="message-nth px-5 py-3 mt-3" v-for="message of chartMessage.messages" :key="message">{{message}}</div>
                                </div>
                                <div class="mr-3" v-if="!chartMessage.nth">
                                    <span class="subtext">40 minutos</span><span class="font-bold ml-3">{{chartMessage.from}}</span>
                                    <div class="message-2nth px-5 py-3 mt-3" v-for="message of chartMessage.messages" :key="message">{{message}}</div>
                                </div>
                                <img v-if="!chartMessage.nth" :src="chartMessage.url" width="36" height="36" class="border-circle">
                            </li>
                        </ul>
<!--                        <div class="p-inputgroup write-message mt-3">-->
<!--                            <span class="p-inputgroup-addon">-->
<!--                                <Button type="button" icon="pi pi-plus-circle" class="p-button-text p-button-plain"></Button>-->
<!--                            </span>-->
<!--                            <InputText placeholder="Write your message (Hint: 'PrimeVue')" @keydown="onChatKeydown($event)" />-->
<!--                        </div>-->
                    </div>
                </div>
            </div>
            <div class="col-12 md:col-6 xl:col-3">
                <div class="card widget-pies">
                    <div class="card-header flex align-items-center justify-content-between">
                        <span class="font-bold">Status de Pedidos</span>
                        <div class="header-right-section flex align-items-center">
                            <Button type="button" icon="pi pi-angle-left" class="p-button-outlined p-button-rounded p-button-plain p-button-sm font-bold mr-2 p-2"></Button>
                            <Button type="button" icon="pi pi-angle-right" class="p-button-outlined p-button-rounded p-button-plain p-button-sm font-bold mr-3 p-2"></Button>
                            <span class="font-bold">1</span><span>/5</span>
                        </div>
                    </div>
                    <div class="flex flex-column align-items-center justify-content-center mt-4">
                        <Chart type="doughnut" :data="revenueChart" :options="revenueOptions" style="position:relative; width: 50%" class="m-auto mb-5"></Chart>
                        <span class="font-bold mb-2">Total Revenda Mês</span>
                        <span class="font-bold text-6xl mb-2">88k</span>
                        <span class="mb-4" style="color: #27AB83">+21%<span class="subtext"> a mais que o último</span></span>
                        <div class="flex align-items-center justify-content-center">
                            <div>
                                <i class="pi pi-circle-on" style="color:#1992D4"></i>
                                <span class="subtext ml-2">Faturado</span>
                            </div>
                            <div class="ml-3">
                                <i class="pi pi-circle-on" style="color:#3E4C59"></i>
                                <span class="subtext ml-2">Confirmado</span>
                            </div>
                            <div class="ml-3">
                                <i class="pi pi-circle-on" style="color:#E4E7EB"></i>
                                <span class="subtext ml-2">Elaboração</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 md:col-6 xl:col-3">
                <div class="card widget-list-type1">
                    <div class="card-header font-bold mb-5">Overview de Usuários</div>
                    <div class="flex align-items-center justify-content-between pb-2 border-none border-bottom-1 border-solid">
                        <span class="font-bold subtext">Departmento</span>
                        <span class="font-bold subtext">Usuários</span>
                    </div>
                    <ul class="list-none p-0 mt-0">
                        <li class="flex flex-wrap align-items-center justify-content-between pt-3">
                            <span class="font-bold">Representante</span>
                            <AvatarGroup class="w-8rem justify-content-evenly">
                                <Avatar image="demo/images/avatar/amyelsner.png" shape="circle"></Avatar>
                                <Avatar image="demo/images/avatar/asiyajavayant.png" shape="circle"></Avatar>
                                <Avatar image="demo/images/avatar/onyamalimba.png" shape="circle"></Avatar>
                                <Avatar image="demo/images/avatar/ionibowcher.png" shape="circle"></Avatar>
                            </AvatarGroup>
                        </li>
                        <li class="flex flex-wrap align-items-center justify-content-between pt-3">
                            <span class="font-bold">Gerência</span>
                            <AvatarGroup class="w-6rem justify-content-evenly">
                                <Avatar image="demo/images/avatar/annafali.png" shape="circle"></Avatar>
                                <Avatar image="demo/images/avatar/bernardodominic.png" shape="circle"></Avatar>
                                <Avatar image="demo/images/avatar/elwinsharvill.png" shape="circle"></Avatar>
                            </AvatarGroup>
                        </li>
                        <li class="flex flex-wrap align-items-center justify-content-between pt-3">
                            <span class="font-bold">Supervisão</span>
                            <AvatarGroup class="w-4rem justify-content-evenly">
                                <Avatar image="demo/images/avatar/ivanmagalhaes.png" shape="circle"></Avatar>
                                <Avatar image="demo/images/avatar/onyamalimba.png" shape="circle"></Avatar>
                            </AvatarGroup>
                        </li>
                        <li class="flex flex-wrap align-items-center justify-content-between pt-3">
                            <span class="font-bold">Suporte</span>
                            <AvatarGroup class="w-8rem justify-content-evenly">
                                <Avatar image="demo/images/avatar/amyelsner.png" shape="circle"></Avatar>
                                <Avatar image="demo/images/avatar/asiyajavayant.png" shape="circle"></Avatar>
                                <Avatar image="demo/images/avatar/onyamalimba.png" shape="circle"></Avatar>
                                <Avatar image="demo/images/avatar/ionibowcher.png" shape="circle"></Avatar>
                            </AvatarGroup>
                        </li>
                        <li class="flex flex-wrap align-items-center justify-content-between pt-3">
                            <span class="font-bold">Marketing</span>
                            <AvatarGroup class="w-6rem justify-content-evenly">
                                <Avatar image="demo/images/avatar/amyelsner.png" shape="circle"></Avatar>
                                <Avatar image="demo/images/avatar/asiyajavayant.png" shape="circle"></Avatar>
                                <Avatar image="demo/images/avatar/onyamalimba.png" shape="circle"></Avatar>
                            </AvatarGroup>
                        </li>


                    </ul>
                </div>
            </div>

            <div class="col-12 md:col-6 xl:col-3">
                <div class="card widget-blog p-0">
                    <div class="card-header relative">
                        <img src="layout/images/bg-product-novo-2.jpg" alt="blog-bg" class="w-full h-full">
                        <div class="header-right-section flex align-items-center absolute right-0 top-0 p-3">
                            <Button type="button" icon="pi pi-angle-left" class="p-button-outlined p-button-rounded p-button-plain p-button-sm font-bold text-white border-white mr-2 p-2"></Button>
                            <Button type="button" icon="pi pi-angle-right" class="p-button-outlined p-button-rounded p-button-plain p-button-sm font-bold text-white border-white mr-3 p-2"></Button>
                            <span class="font-bold text-white">1</span><span class="text-white">/5</span>
                        </div>
                    </div>
                    <div class="card-content p-4">
                        <div class="flex align-items-center justify-content-between mb-3">
                            <span class="font-bold">Nova mídia de produto disponível <i class="pi pi-check-circle ml-3 text-xl" style="color: #27AB83"></i></span>
                            <i class="pi pi-bookmark text-xl subtext"></i>
                        </div>
                        <p class="mb-5">Maria da Silva </p>
                        <div class="flex align-items-center justify-content-between">
                            <img src="demo/images/avatar/amyelsner.png" alt="avatar" width="36" height="36" class="border-circle">
                            <div class="subtext">
                                <i class="pi pi-heart mr-1"></i>
                                <span>888</span>
                                <i class="pi pi-eye ml-4 mr-1"></i>
                                <span>8888</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 md:col-6 xl:col-3">
                <div class="card widget-list-type2">
                    <div class="card-header font-bold">Grupos em Destaque</div>
                    <ul class="list-none p-0">
                        <li class="flex align-items-center justify-content-between pt-2 pb-3">
                            <div class="flex align-items-center justify-content-start">
                                <img src="layout/images/produtos/0001.jpg" alt="verona-layout" width="42" height="42" class="border-round"/>
                                <div class="ml-2">
                                    <span class="font-bold block">Grupo teste 001</span>
                                    <span class="subtext text-sm block">48</span>
                                </div>
                            </div>

                            <div class="pie-wrapper progress-75">
                                <span class="label font-bold text-sm absolute">75%</span>
                                <div class="pie">
                                    <div class="left-side half-circle"></div>
                                    <div class="right-side half-circle"></div>
                                </div>
                            </div>
                        </li>
                        <li class="flex align-items-center justify-content-between pt-2 pb-3">
                            <div class="flex align-items-center justify-content-start">
                                <img src="layout/images/produtos/0002.jpg" alt="verona-layout" width="42" height="42" class="border-round"/>
                                <div class="ml-2">
                                    <span class="font-bold block">Grupo Testes 900</span>
                                    <span class="subtext text-sm block">49</span>
                                </div>
                            </div>

                            <div class="pie-wrapper progress-68">
                                <span class="label font-bold text-sm absolute">68%</span>
                                <div class="pie">
                                    <div class="left-side half-circle"></div>
                                    <div class="right-side half-circle"></div>
                                </div>
                            </div>
                        </li>
                        <li class="flex align-items-center justify-content-between pt-2 pb-3">
                            <div class="flex align-items-center justify-content-start">
                                <img src="layout/images/produtos/0003.jpg" alt="verona-layout" width="42" height="42" class="border-round"/>
                                <div class="ml-2">
                                    <span class="font-bold block">Grupo Testes 800</span>
                                    <span class="subtext text-sm block">32</span>
                                </div>
                            </div>

                            <div class="pie-wrapper progress-42">
                                <span class="label font-bold text-sm absolute">42%</span>
                                <div class="pie">
                                    <div class="left-side half-circle"></div>
                                    <div class="right-side half-circle"></div>
                                </div>
                            </div>
                        </li>
                        <li class="flex align-items-center justify-content-between pt-2 pb-3">
                            <div class="flex align-items-center justify-content-start">
                                <img src="layout/images/produtos/0001.jpg" alt="verona-layout" width="42" height="42" class="border-round"/>
                                <div class="ml-2">
                                    <span class="font-bold block">Grupo Testes 900</span>
                                    <span class="subtext text-sm block">18</span>
                                </div>
                            </div>

                            <div class="pie-wrapper progress-10">
                                <span class="label font-bold text-sm absolute">10%</span>
                                <div class="pie">
                                    <div class="left-side half-circle"></div>
                                    <div class="right-side half-circle"></div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 md:col-6 lg:col-6">
                <div class="card widget-teamstatus">
                    <div class="card-header font-bold pl-2">Supervisores em Destaque</div>
                    <div class="flex flex-wrap align-items-start justify-content-around pt-5">
<!--                        <div class="flex flex-column align-items-center justify-content-center mb-2">-->
<!--                            <a href="#" class="new-button border-circle flex align-items-center justify-content-center mb-2">-->
<!--                                <i class="pi pi-plus"></i>-->
<!--                            </a>-->
<!--                            <span class="font-bold text-sm">Add New</span>-->
<!--                        </div>-->
                        <div class="flex flex-column align-items-center justify-content-center mb-2">
                            <div class="member border-solid border-circle flex align-items-center justify-content-center relative">
                                <img src="demo/images/avatar/amyelsner.png" width="44" height="44" class="border-circle">
                                <span class="member-badge absolute"><i class="pi pi-globe text-sm"></i></span>
                            </div>
                            <span class="font-bold">Darlene da Silva</span>
                            <span class="subtext text-sm">R$ 700.000,00</span>
                        </div>
                        <div class="flex flex-column align-items-center justify-content-center mb-2">
                            <div class="member border-solid border-circle flex align-items-center justify-content-center relative">
                                <img src="demo/images/avatar/annafali.png" width="44" height="44" class="border-circle">
                                <span class="member-badge absolute"><i class="pi pi-globe text-sm"></i></span>
                            </div>
                            <span class="font-bold">Alberto Flores</span>
                            <span class="subtext text-sm">R$ 600.000,00</span>
                        </div>
                        <div class="flex flex-column align-items-center justify-content-center mb-2">
                            <div class="member border-solid border-circle flex align-items-center justify-content-center relative">
                                <img src="demo/images/avatar/asiyajavayant.png" width="44" height="44" class="border-circle">
                                <span class="member-badge absolute"><i class="pi pi-globe text-sm"></i></span>
                            </div>
                            <span class="font-bold">Annete da Siva</span>
                            <span class="subtext text-sm">R$ 500.000,00</span>
                        </div>
                        <div class="flex flex-column align-items-center justify-content-center mb-2">
                            <div class="member border-solid border-circle flex align-items-center justify-content-center relative">
                                <img src="demo/images/avatar/bernardodominic.png" width="44" height="44" class="border-circle">
                                <span class="member-badge absolute"><i class="pi pi-globe text-sm"></i></span>
                            </div>
                            <span class="font-bold">Rafael da Silva</span>
                            <span class="subtext text-sm">R$ 400.000,00</span>
                        </div>
                    </div>
                </div>
                <div class="card widget-teamstatus">
                    <div class="card-header font-bold pl-2">Representantes em Destaque</div>
                    <div class="flex flex-wrap align-items-start justify-content-around pt-5">
<!--                        <div class="flex flex-column align-items-center justify-content-center mb-2">-->
<!--                            <a href="#" class="new-button border-circle flex align-items-center justify-content-center mb-2">-->
<!--                                <i class="pi pi-plus"></i>-->
<!--                            </a>-->
<!--                            <span class="font-bold text-sm">Add New</span>-->
<!--                        </div>-->
                        <div class="flex flex-column align-items-center justify-content-center mb-2">
                            <div class="member border-solid border-circle flex align-items-center justify-content-center relative">
                                <img src="demo/images/avatar/ionibowcher.png" width="44" height="44" class="border-circle">
                                <span class="member-badge absolute"><i class="pi pi-globe text-sm"></i></span>
                            </div>
                            <span class="font-bold">Daniela da Silva</span>
                            <span class="subtext text-sm">R$ 400.000,00</span>
                        </div>
                        <div class="flex flex-column align-items-center justify-content-center mb-2">
                            <div class="member border-solid border-circle flex align-items-center justify-content-center relative">
                                <img src="demo/images/avatar/onyamalimba.png" width="44" height="44" class="border-circle">
                                <span class="member-badge absolute"><i class="pi pi-globe text-sm"></i></span>
                            </div>
                            <span class="font-bold">João dos Santos</span>
                            <span class="subtext text-sm">R$ 300.000,00</span>
                        </div>
                        <div class="flex flex-column align-items-center justify-content-center mb-2">
                            <div class="member border-solid border-circle flex align-items-center justify-content-center relative">
                                <img src="demo/images/avatar/elwinsharvill.png" width="44" height="44" class="border-circle">
                                <span class="member-badge absolute"><i class="pi pi-globe text-sm"></i></span>
                            </div>
                            <span class="font-bold">Katarina da Silva</span>
                            <span class="subtext text-sm">R$ 200.000,00</span>
                        </div>
                        <div class="flex flex-column align-items-center justify-content-center mb-2">
                            <div class="member border-solid border-circle flex align-items-center justify-content-center relative">
                                <img src="demo/images/avatar/stephenshaw.png" width="44" height="44" class="border-circle">
                                <span class="member-badge absolute"><i class="pi pi-globe text-sm"></i></span>
                            </div>
                            <span class="font-bold">José da Silva</span>
                            <span class="subtext text-sm">R$ 100.000,00</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProductService from "../service/ProductService";

export default {
    data() {
        return {
            calendar: null,
            orderWeek: [
                {name: 'Semana Atual', code: '0'},
                {name: 'Semana Passada', code: '1'}
            ],
            selectedOrderWeek: null,
            products: null,
            productsThisWeek: null,
            productsLastWeek: null,
            selectedDrop: null,
            revenueChart: {
                labels: [
                    'Online',
                    'Retail',
                    'N/A'
                ],
                datasets: [{
                    data:  [12, 32, 56],
                    backgroundColor: [  '#1992D4', '#3E4C59', '#E4E7EB'],
                    borderWidth: 0,
                }]
            },
            revenueOptions: {
                plugins: {
                    legend: {
                        display: false,
                    }
                },
                responsive: true,
                cutout: 60
            },
            chatMessages: [
                { nth: true, from: 'João da Silva', url: 'demo/images/avatar/stephenshaw.png', messages: ['Rio de Janeiro. '] },
                { nth: false, from: 'Jairo dos Santos', url: 'demo/images/avatar/ivanmagalhaes.png', messages: ['São Paulo'] },
                { nth: true, from: 'Darlene da Silva', url: 'demo/images/avatar/amyelsner.png', messages: ['Belo Horizonte'] },
            ],
            activeTab: 0,
            activeListItemIndex: 1,
            activeListItem: {image: 'layout/images/dashboard/0004.png', text: 'Produto Teste 0900', subtext: 'Fabricante 000', ratio: '+24%'},
            listItems: [
                {image: 'layout/images/produtos/0001.jpg', text: 'Produto Teste 001', subtext: 'Fabricante 002', ratio: '+40%'},
                {image: 'layout/images/produtos/0002.jpg', text: 'Produto Teste 002', subtext: 'Fabricante 008', ratio: '+24%'},
                {image: 'layout/images/produtos/0003.jpg', text: 'Produto Teste 003', subtext: 'Fabricante 010', ratio: '+17%'}
            ],
            analytics: [
                {label: 'Products', value: 1},
                {label: 'Sales', value: 2},
                {label: 'Customers', value: 3},
            ]
        }
    },
    productService: null,
    created() {
        this.productService = new ProductService();
    },
    mounted() {
        this.selectedOrderWeek = this.orderWeek[0];

        this.productService.getProducts().then(data => this.products = data.slice(0, 5));
        this.productService.getProducts().then(data => this.productsThisWeek = data.slice(0, 5));
        this.productService.getProductsMixed().then(data => this.productsLastWeek = data.slice(0, 5));
    },
    methods: {
        recentSales(event) {
            if (event.value.code === '0') {
                this.products = this.productsThisWeek;
            } else {
                this.products = this.productsLastWeek;
            }
        },
        onChatKeydown(event) {
            if (event.key === 'Enter') {
				let message = event.currentTarget.value;
				let lastMessage = this.chatMessages[this.chatMessages.length - 1];

				if (lastMessage.from) {
					// this.chatMessages.push({ messages: [message] });
                    this.chatMessages.push({ nth: false, from: 'Verona',
                        url: 'layout/images/logo-' + (this.$appState.colorScheme === 'light' ? 'dark' : 'white') + '.png',
                        messages: [message] });
				}
				else {
					lastMessage.messages.push(message);
				}

				if (message.match(/primeng|primereact|primefaces|primevue/i)) {
					this.chatMessages.push({ nth: true, from: 'Representante 902', url: 'demo/images/avatar/ionibowcher.png', messages: ['Always bet on Prime!'] });
				}

				event.currentTarget.value = '';
				this.chatInput = '';

				const el = document.getElementById('chatcontainer');
				setTimeout(() => {
					el.scroll({
						top: el.scrollHeight,
						behavior: 'smooth'
					});
				}, 1);
			}
        },
        onTabClick(event, index) {
            this.activeTab = index;

            if (index === 0) {
                this.listItems = [
                    {image: 'layout/images/dashboard/0005.png', text: 'Produto Teste 090', subtext: 'Fabricante 001', ratio: '+40%'},
                    {image: 'layout/images/dashboard/0006.png', text: 'Produto Teste 091', subtext: 'Fabricante 001', ratio: '+24%'},
                    {image: 'layout/images/dashboard/0007.png', text: 'Produto Teste 092', subtext: 'Fabricante 001', ratio: '+17%'}
                ];
            } else if (index === 1) {
                this.listItems = [
                    {image: 'layout/images/dashboard/0008.png', text: 'Produto Teste 100', subtext: 'Fabricante 002', ratio: '+27%'},
                    {image: 'layout/images/dashboard/0001.png', text: 'Produto Teste 101', subtext: 'Fabricante 002', ratio: '+41%'},
                    {image: 'layout/images/dashboard/0002.png', text: 'Produto Teste 102', subtext: 'Fabricante 002', ratio: '+56%'}
                ];
            } else if (index === 2) {
                this.listItems = [
                    {image: 'layout/images/dashboard/0003.png', text: 'Produto Teste 200', subtext: 'Fabricante 003', ratio: '+34%'},
                    {image: 'layout/images/dashboard/0004.png', text: 'Produto Teste 201', subtext: 'Fabricante 001', ratio: '+67%'},
                    {image: 'layout/images/dashboard/0005.png', text: 'Produto Teste 202', subtext: 'Fabricante 002', ratio: '+8%'}
                ];
            }

            this.activeListItem = this.listItems[this.activeListItemIndex];
        },
        formatCurrency(value) {
            return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
        }
    }
}
</script>

<style lang="scss" scoped>
.p-dropdown {
    width: 14rem;
}
</style>
